import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog"; 
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css";
import "./../flag.css";
import axios from "axios";
import Storage from "../utils/storage";
import Config from "./../Config";
import { useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { getAccessToken, deleteStoredData } from "../utils/http";
import { getSupplierService, toggleEnableService, pendingEmailService,completedEmailService } from "../services/EmailReminderService.js";
import { showErrorToast, showSuccessToast, showWarningToast } from "../services/ToastService";

export default function EmailReminder() {
  const userId = Storage.get("user");
  const navigate = useNavigate();
  const Approved = "Approved";
  const Pending = "Pending";
  const InReview = "In Review";
  const InProgress = "In Progress";
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const toast = useRef(null);
  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [data, setData] = useState(null);
  const [updatedRows, setUpdatedRows] = useState(null);
  const token = getAccessToken();

  const isSelectable = (data) => data.Status !== undefined;

  const isRowSelectable = (event) =>
    event.data ? isSelectable(event.data) : true;

  const rowClassName = (data) => (isSelectable(data) ? "" : "p-disabled");

  const getSuppliers = () => {
   getSupplierService(token,(res) => {
        setData(res?.data);
      },
       (e) => {});
  };
  useEffect(() => {
    getSuppliers();
  }, []);

  const onToggleEnable = (rowData) => {
    const updatedData = data.map((item) =>
      item.Id === rowData.Id
        ? { ...item, IsEmailEnabled: !item.IsEmailEnabled }
        : item
    );
    // Track the updated rows
    setUpdatedRows({ Id: rowData.Id, IsEmailEnabled: !rowData.IsEmailEnabled });

    setData(updatedData);
    let emptyProduct = [
      {
        SupplierId: rowData.Id,
        IsEmailEnabled: !rowData.IsEmailEnabled,
      },
    ];
    toggleEnableService(emptyProduct,token,(res) => {
        const resdata = res;
        if (resdata.status === 200) {
          showSuccessToast(toast, "Status Updated Successfully");
        } else if (resdata.status === 401) {
          showWarningToast(toast, resdata?.statusText);
        } else {
          showErrorToast(toast, resdata?.statusText);
        }
      },
      (e) => {});
  };

  const setFilterALL = () => {
    setGlobalFilter(" ");
  };
  const setFilterInProgress = () => {
    setGlobalFilter(InProgress);
  };
  const setFilterPending = () => {
    setGlobalFilter(Pending);
  };
  const setFilterCompleted = () => {
    setGlobalFilter(Approved);
  };
  const setFilterInReview = () => {
    setGlobalFilter(InReview);
  };

  const pendingMail = () => {
    let suppliers = selectedItems?.filter((obj) => obj.IsEmailEnabled).map((obj) => ({
        SupplierId: obj.Id,
        IsEmailEnabled: obj.IsEmailEnabled,
      }));
   pendingEmailService(suppliers,token,(res) => {
        const resdata = res;
        if (resdata.status === 200) {
          showSuccessToast(toast, "Email Sent Successfully");
        } else if (resdata.status === 401) {
          showWarningToast(toast, resdata?.statusText);
        } else {
          showErrorToast(toast, resdata?.statusText);
        }
      },
      (e) => {});
  };

  const completedMail = () => {
    let suppliers = selectedItems?.filter((obj) => obj.IsEmailEnabled).map((obj) => ({
        SupplierId : obj.Id,
        IsEmailEnabled : obj.IsEmailEnabled,
      }));

   completedEmailService(suppliers,token,(res) => {
        const resdata = res;
        if (resdata.status === 200) {
          showSuccessToast(toast, "Email Sent Successfully");
        } else if (resdata.status === 401) {
          showWarningToast(toast, resdata?.statusText);
        } else {
          showErrorToast(toast, resdata?.statusText);
        }
      },
      (e) => {});
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Supplier Email Reminder </h4>

      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <div className="flex flex-wrap gap-2">
          <Button
            label="ALL"
            style={{ backgroundColor: "#003e7e" }}
            onClick={setFilterALL}
          />
          <Button
            label="Pending"
            style={{ backgroundColor: "#003e7e" }}
            onClick={setFilterPending}
          />
          <Button
            label="Completed"
            style={{ backgroundColor: "#003e7e" }}
            onClick={setFilterCompleted}
          />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
          />
        </div>
      </span>
    </div>
  );
  const leftToolbarTemplate = () => {
    var pending = selectedItems.find((obj) => {
      return obj.Status === "Pending";
    });

    var approved = selectedItems.find((obj) => {
      return obj.Status === "Approved";
    });

    var isEmailEnabled = selectedItems.find((obj) => {
      return obj.IsEmailEnabled === false || obj.IsEmailEnabled === null;
    });

    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Send Pending Email"
          style={{ backgroundColor: "#003e7e" }}
          severity="success"
          onClick={pendingMail}
          disabled={
            !selectedItems ||
            !(selectedItems.length > 0) ||
            (pending && approved) ||
            !pending 
            
          }
        />
        <Button
          label="Send Completed Email"
          style={{ backgroundColor: "#003e7e" }}
          severity="success"
          onClick={completedMail}
          disabled={
            !selectedItems ||
            !(selectedItems.length > 0) ||
            (pending && approved) ||
            !approved
          }
        />
      </div>
    );
  };
  return (
    <div>
      <Toast ref={toast} />

      <ConfirmDialog />

      <div className="card">
        <div className="flex justify-content-center mb-4"></div>
        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
        <DataTable
          ref={dt}
          value={data}
          selectionMode="multiple"
          selection={selectedItems}
          isDataSelectable={isRowSelectable}
          rowClassName={rowClassName}
          onSelectionChange={(e) => setSelectedItems(e.value)}
          dataKey="Id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
          header={header}
          metaKeySelection={false}
        >
          <Column selectionMode="multiple" style={{ width: "3em" }} />
          <Column
            field="Name"
            header="Supplier Name"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="Status"
            header="Status"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="IsEmailEnabled"
            header="Email Status"
            body={(rowData) => (
              <InputSwitch
                checked={rowData.IsEmailEnabled}
                onChange={() => onToggleEnable(rowData)}
              />
            )}
          />
        </DataTable>
      </div>
    </div>
  );
}
