import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Logo from "./../assets/images/logo.png";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Storage from "../utils/storage";
import "./../App.css";
import { isAdmin, isApproverOrViewer, isSupplier, isInternalEnricher, isExternalEnricher, isQuality, isESG, isExternalAuditor} from "../utils/roleValidator";
 
 
const drawerWidth = 212;
 
function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const Role = Storage.get("Role");
  // console.log("SidebarRole", lowerCased);
 
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
 
  const mappings = {
    "admin": {
      "User Management": "/usermanagement",
      "Supplier Management": "/suppliermanagement",
      "Email Reminder": "/emailreminder"
    },
    "supplier": {
      "Welcome": "/welcome",
      "Item List": "/itemslist"
    },
    "approver": {
      "Dashboard": "/dashboardapprover",
      "Item List": "/itemslistapprover"
    },
    "internal": {
      "Dashboard": "/welcome",
      "Item List": "/itemslist"
    },
    "external": {
      "Dashboard": "/welcome",
      "Item List": "/itemslist"
    },
    "esg": {
      "Dashboard": "/dashboard",
      "Survey Responses": "/surveyResultsList",
      "Item List": "/itemslistapprover"
    },
    "auditor": {
      "Item List": "/itemlist"
    }
  };
 
 
  const drawer = (
    <div>
      <Toolbar>
        <div className="logo-section">
          <img src={Logo} alt="Bunzl App" className="logo-sidebar-image" />
 
          <span
            id="menuToggle"
            style={{ paddingLeft: "14px", fontSize: "20px" }}
          >
            <i className="fa fa-bars"></i>
          </span>
        </div>
      </Toolbar>
      <Divider />
      { isAdmin(Role) && (
        <List>
            {Object.keys(mappings["admin"]).map((text, index) => (
            <Link
              style={{ textDecorationLine: "none" }}
              to={mappings["admin"][text]}
            >
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>)}
      {isSupplier(Role) && (
        <List>
          {Object.keys(mappings["supplier"]).map((text, index) => (
            <Link
              style={{ textDecorationLine: "none" }}
              to={mappings["supplier"][text]}
              onClick={ (text === "Item List" && !props.surveyPassed) ? (event) => { event.preventDefault(); } : undefined }
              >
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>)}
      {(isApproverOrViewer(Role) || isQuality(Role)) && (
        <List>
          {Object.keys(mappings["approver"]).map((text, index) => (
            <Link
              style={{ textDecorationLine: "none" }}
              to={mappings["approver"][text]}
            >
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>)}
      {isInternalEnricher(Role) && (
        <List>
          {Object.keys(mappings["internal"]).map((text, index) => (
            <Link
              style={{ textDecorationLine: "none" }}
              to={mappings["internal"][text]}
              >
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>)}
        { isExternalEnricher(Role) && (
          <List>
            {Object.keys(mappings["external"]).map((text, index) => (
            <Link
              style={{ textDecorationLine: "none" }}
              to={mappings["external"][text]}
              >
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>)}
          { isESG(Role) && (
          <List>
            {Object.keys(mappings["esg"]).map((text, index) => (
            <Link
              style={{ textDecorationLine: "none" }}
              to={mappings["esg"][text]}
              >
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>)}
          { isExternalAuditor(Role) && (
          <List>
            {Object.keys(mappings["auditor"]).map((text, index) => (
            <Link
              style={{ textDecorationLine: "none" }}
              to={mappings["auditor"][text]}
              >
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>)}

    </div>
  );
 
  const container =
    window !== undefined ? () => window().document.body : undefined;
 
  return (
    <Box sx={{ display: "flex", backgroundcolor: "#13233b" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              zIndex: '1101'
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#13233b",
              zIndex: '1101'
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
 
ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
 
export default ResponsiveDrawer;