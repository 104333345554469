import React, { useEffect, useState,useRef } from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Dashboard from "./Dashboard";
import Profile from "./../pages/Profile";
import Logout from "../pages/Logout";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Logo from "./../assets/images/logo.png";
import Avtar from "./../assets/images/avatar.jpg";
import "./../App.css";
import ItemsList from "../pages/ItemsList";
import ForgotPassword from "../pages/ForgotPassword";
import ImportSupplier from "../pages/ImportSupplier";
import UserManagement from "../pages/UserManagement";
import DashboardApprover from "./DashboardApprover";
import ItemsListApprover from "../pages/ItemsListApprover";
import EmailReminder from "../pages/EmailReminder";
import { useDispatch, useSelector } from "react-redux";
import Storage from "../utils/storage";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import useInterval from './useInterval';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Config from "./../Config";
import axios from "axios";
import Notifications from "./Notification";
import {  
  getAccessToken ,
  deleteStoredData
} from "../utils/http";
import PageNotFound from "./PageNotFound";
import { isSupplier, isAdmin, isApproverOrViewer, isQuality, isInternalEnricher, isExternalEnricher, isNotAnyRole, isESG, isExternalAuditor } from "./../utils/roleValidator";
import {fetchNotificationService , handleNotificationClickService} from "../services/LayoutService"
import NewDashboard from "./NewDashboard";
import SurveyList from "../pages/SurveyList";
import SupplierManagement from "../pages/SupplierManagement";
 
const Layout = () => {
  const token = getAccessToken();
  const UserId = Storage.get("user");
  const toast = useRef(null);
  const toastBC = useRef(null);
  const [visible, setVisible] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [count, setCount] = useState(0);
  const [countClear, setCountClear] = useState(1);
  const [isRunning, setIsRunning] = useState('');
  const [notificationId, setIsNotificationId] = useState('');
  const [surveyPassed, setSurveyPassed] = useState(Storage.get("surveyPassed") ? Storage.get("surveyPassed") : false);
  const [survey, setSurvey] = useState(Storage.get("survey"));
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    Storage.set("surveyPassed", surveyPassed);
}, [surveyPassed]);
  if (Storage.get("survey") == null) {
    const surveySkipped = {};
    const surveySubmitted = {}
    Storage.get("suppliers")?.Suppliers?.forEach(supplier => {
      surveySkipped[supplier.id] = false;
      surveySubmitted[supplier.id] = false;
    });
    Storage.set("survey", [surveySkipped, surveySubmitted]);
    setSurvey([surveySkipped, surveySubmitted]);
  }
 
  const user = useSelector((store) =>
    ("storedata ", store.User?.UserId)
  );
 
  const fetchNotifications = async () => {
    try {
      fetchNotificationService(
        UserId,
        token,
        (res) => {
          const newNotifications = res.data || [];
          // Filter out already fetched notifications
          const uniqueNotifications = newNotifications.filter(
            (item) => !notifications.some((notification) => notification.Id === item.Id)
          );
          setNotifications((prevNotifications) => [...prevNotifications, ...uniqueNotifications]);
        },
        (error) => {
          console.error(error);
          // Handle the error, such as displaying an error message to the user
        }
      );
    } catch (error) {
      console.error(error);
      // Handle any other errors that might occur during the function execution
    }
  };
 
  const handleNotificationClick = async (e, id) => {
    setIsRunning('');
    let outerDiv = e.target.closest(".p-toast-message-content");
    let close = outerDiv.querySelector("button.p-toast-icon-close.p-link");
    close.click();
 
    try {
      const res = await handleNotificationClickService(id, token,
        (res) => {
          // Handle success if needed
        },
        (error) => {
          // Handle error if needed
        }
      );
    } catch (error) {
      // Handle any other errors that might occur during the function execution
    }
  };
 
  useInterval(() => {
    fetchNotifications();
  }, 1000 * 300); // Polling every 30 mins

  useEffect(() => {
    // Check if token is not null and it's not a page refresh
    if (token && !localStorage.getItem('pageRefreshed')) {
      fetchNotifications();
    }
    // Set pageRefreshed flag to indicate that the page has been refreshed
    localStorage.setItem('pageRefreshed', 'true');
  
    // Cleanup function to remove the pageRefreshed flag when the component unmounts
    return () => {
      localStorage.removeItem('pageRefreshed');
    };
  }, [token]); 
     
  const Role = Storage.get("Role");
  const suppliersgroup= Storage.get("suppliers");
  const suppliergroupname= suppliersgroup?.GroupName
  const suppliers = suppliersgroup?.Suppliers
  const [selectedSuppliers, setSelectedSuppliers] = useState(suppliers);
  const onSupplierChange = (e) => {
    if (e.value.length < 1) {
      return;
    }
    setSelectedSuppliers(e.value);
}
if(selectedSuppliers?.length !== 0){
  Storage.set('selectedSuppliers',selectedSuppliers)
} else {
  Storage.remove('selectedSuppliers')
}
 
 
console.log(Role)
const footerContent = (
  <div>
      <Button label="Ok" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus />
  </div>
);
 
  return (
    <>
      <Toast ref={toast} />
      <Notifications notifications={notifications} onNotificationClick={handleNotificationClick} />
 
      <Dialog header="Important!" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
      {isRunning}            
      </Dialog>
      <div className={toggle ? "row1 open" : "row1"}>
        <div className="col-md-12">
          <div className="header-top">
            <div className="logo-section">
              {/* <img src={Logo} alt="Bunzl App" /> */}
 
              <span
                id="menuToggle"
                onClick={() => {
                  setToggle({ toggle: !toggle });
                }}
                style={{ paddingLeft: "14px", fontSize: "20px" }}
              >
                <i className="fa fa-bars"></i>
              </span>
            </div>
           {isSupplier(Role) ? <div className="left-section">
                  {/* Welcome, <b style={{marginRight:'5px'}}>{suppliergroupname}</b>   */}
                   {/* <Dropdown value={selectedSupplier} options={suppliers} onChange={onSupplierChange} optionLabel="name"  placeholder="Select a Supplier" /> */}
                   <MultiSelect value={selectedSuppliers} onChange={onSupplierChange} options={suppliers} optionLabel="name"  display="chip"
    placeholder="Select a Supplier" maxSelectedLabels={3} className="w-full md:w-20rem" />
              </div> : ''}
            <div className="right-section">
              <div className="user-area dropdown">
                <span className="dropdown-toggle active">
                  {" "}
                  Welcome, {Storage.get("user")}
                  <img
                    alt="User Avatar"
                    className="user-avatar rounded-circle"
                    src={Avtar}
                  />
                </span>
                <div className="user-menu">
                  <div className="nav-link">
                    <Link to="/profile">
                      <i className="fa fa-cog"></i>Change Password
                    </Link>
                  </div>
                  {/* <div className="nav-link" onClick={(e) => { sessionStorage.removeItem("LoggedInUser"); window.location = '/login' }}><i className="fa fa-power-off"></i>Logout</div> */}
                  <div className="nav-link">
                    <Logout />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="left-panel">
          <Sidebar surveyPassed={surveyPassed} />
        </div>
        <div className="right-panel">
          {/* <Router> */}
          { isAdmin(Role) && (
            <Routes>
              <Route
                path="*"
                element={<Navigate to="/usermanagement" replace={true} />}
              />
              <Route
                exact
                path="/usermanagement"
                element={<UserManagement />}
              />
              <Route
                exact
                path="/suppliermanagement"
                element={<SupplierManagement/>}
              />
              <Route
                exact
                path="/emailreminder"
                element={<EmailReminder />}
              />
            <Route exact path="/profile" element={<Profile />} />
 
            </Routes>)}
          {isSupplier(Role) && (
            <Routes>
              <Route
                path="*"
                element={<Navigate to="/welcome" replace={true} />}
              />
              <Route index exact path="/welcome" element={<Dashboard setSurveyPassed={setSurveyPassed} surveyPassed={surveyPassed} survey={survey} selectedSuppliers={selectedSuppliers}/>} />
              {/* <Route index exact path="/welcome" element={<Dashboard/>} /> */}
              <Route exact path="/profile" element={<Profile />} />
              {surveyPassed && <Route exact path="/itemslist" element={<ItemsList />} /> }
               {/* <Route exact path="/itemslist" element={<ItemsList />} /> */}
              <Route
                exact
                path="/forgotpassword"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/importsupplier"
                element={<ImportSupplier />}
              />
            </Routes>)}
          {isInternalEnricher(Role) && (
            <Routes>
              <Route
                path="*"
                element={<Navigate to="/welcome" replace={true} />}
              />
              <Route index exact path="/welcome" element={<NewDashboard/>} />
              <Route exact path="/profile" element={<Profile />} />
               <Route exact path="/itemslist" element={<ItemsList />} /> 
              <Route
                exact
                path="/forgotpassword"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/importsupplier"
                element={<ImportSupplier />}
              />
            </Routes>)}
           {isExternalEnricher(Role) && (
            <Routes>
              <Route
                path="*"
                element={<Navigate to="/welcome" replace={true} />}
              />
              <Route index exact path="/welcome" element={<NewDashboard/>} />
              <Route exact path="/profile" element={<Profile />} />
               <Route exact path="/itemslist" element={<ItemsList />} /> 
              <Route
                exact
                path="/forgotpassword"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/importsupplier"
                element={<ImportSupplier />}
              />
            </Routes>)}
             {isApproverOrViewer(Role) && (
            <Routes>
              <Route
                path="*"
                element={<Navigate to="/dashboardapprover" replace={true} />}
              />
              <Route index exact path="/dashboardapprover" element={<DashboardApprover />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/itemslistapprover" element={<ItemsListApprover />} />
              <Route
                exact
                path="/forgotpassword"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/importsupplier"
                element={<ImportSupplier />}
              />
            </Routes>)}
          {isQuality(Role) && (
            <Routes>
              <Route
                path="*"
                element={<Navigate to="/dashboardapprover" replace={true} />}
              />
              <Route index exact path="/dashboardapprover" element={<NewDashboard />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/itemslistapprover" element={<ItemsListApprover />} />
              <Route
                exact
                path="/forgotpassword"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/importsupplier"
                element={<ImportSupplier />}
              />
            </Routes>)}
            
            {isESG(Role) && (
              <Routes>
                <Route
                  path="*"
                  element={<Navigate to="/dashboard" replace={true}/>}
                />
                <Route index exact path="/dashboard" element={<NewDashboard/>} />
                <Route exact path="/profile" element={<Profile />} />
                <Route exact path="/surveyResultsList" element={<SurveyList />} />
                <Route exact path="/itemslistapprover" element={<ItemsListApprover />} />
                <Route
                  exact
                  path="/forgotpassword"
                  element={<ForgotPassword />}
                />
              </Routes>
            )}
            {isExternalAuditor(Role) && (
              <Routes>
                <Route
                  path="*"
                  element={<Navigate to="/itemlist" replace={true}/>}
                />
                <Route index exact path="/itemlist" element={<ItemsListApprover/>} />
                <Route exact path="/profile" element={<Profile />} />
                <Route
                  exact
                  path="/forgotpassword"
                  element={<ForgotPassword />}
                />
              </Routes>
            )}
          {isNotAnyRole(Role) && (
            <Routes>
              <Route exact path="*" element={<PageNotFound />} />
            </Routes>
            )}
        </div>
        <div className="col-md-12">
          <Footer />
        </div>
 
        <div className="Message">{/* <Message /> */}</div>
      </div>
    </>
  );
};
 
export default Layout;