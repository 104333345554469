import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { saveAs } from "file-saver";
import { MultiSelect } from 'primereact/multiselect';
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css";
import "./../flag.css";
import Config from './../Config'
import Storage from '../utils/storage';
import {  
  getAccessToken ,
  deleteStoredData
} from "../utils/http";
import { showErrorToast, showSuccessToast, showWarningToast, showErrorToastNullable } from "../services/ToastService";
import { changePassword, editSaveUsers, getManufacturersList, getSupplier, getUsers, groupSupplier, saveUsers, viewUsers } from "../services/UserManagementService";
import { emailValidator, firstNameValidator, lastNameValidator, passwordValidator, PASSWORD_REQUIREMENTS } from "../utils/userValidator";
import { InputSwitch } from "primereact/inputswitch";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";

export default function UserManagement() {
 
  const [isvalidFirst,setValidFirst] = useState(false);
  const [isvalidLast,setValidLast] = useState(false);
  const [isvalidEmail, setIsValidEmail] = useState(false);
  const [active, setActive] = useState(true);
  const [userPasswordDailog, setUserPasswordDailog] = useState(false);
  const [inActiveDailog, setInActiveDailog] = useState(false);
  const [activeDailog, setActiveDailog] = useState(false);
  const [viewUserDailog,setViewUserDialog] = useState(false);
  const [viewData, setViewData] = useState(null)
  const [userDialog, setUserDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [vendorListDropdown, setVendorListDropdown] = useState(null);
  const [groupListDropdown, setGroupListDropdown] = useState(null);
  const [vendorDropdown, setVendorDropdown] = useState(null);
  const [groupDropdown, setGroupDropdown] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword,setConfirmPassword] = useState("")
  const [newPassword, setNewPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [validNew, setValidNew] = useState(false);
  const [validateNewErrors, setValidateNewErrors] = useState("");
  const [vendorDropdownAdd, setVendorDropdownAdd] = useState(null);
  const [groupDropdownAdd, setGroupDropdownAdd] = useState(null);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [role, setRole] = useState("")
  const [selectedRole,setSelectedRole] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [data, setData] = useState([]);
  const [validPass, setValidPass] = useState(false);
  const [confirmValid, setValidConfirm] = useState(false);
  const [validationConfirmErrors, setValidationConfirmErrors] = useState("");
  const [validEmail,setValidEmail] = useState(false);
  const [validationNewErrors,setValidationNewErrors] = useState("");
  const [addUserCount, setAddUserCount] = useState(0);
  const [type, setType] = useState([]);
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const [vendorDropdownAddTemp, setVendorDropdownAddTemp] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);
  const token = getAccessToken(); 
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    RoleName: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
 
  useEffect(() => {
    getUser(data);
  },[showInactiveUsers]);

  useEffect(() => {
    getSuppliers();
  }, [groupDropdownAdd]);
  useEffect(() =>
  {
    loadManufacturers();
  },[]);

  const loadManufacturers = () => {
    getManufacturersList(false, token, (res)=> {
      setManufacturerList(res?.data);
    }, (error) => {
      showErrorToastNullable(toast, error?.response?.data?.message);
    });
  };

  const saveUser = () => {
    setAddUserCount(1);
    setSubmitted(true);
    let payload = {
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
      UserName: email,
      Role: role,
      SupplierGroupId: groupDropdownAdd,
      SuppliersIds: vendorDropdownAdd,
      ManufacturersIds: selectedManufacturers,
    };
  
    saveUsers(
      payload,
      token,
      (res) => {
        showSuccessToast(toast, "User Created");
        setUserDialog(false);
        setUserPasswordDailog(false);
        setViewUserDialog(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setRole("");
        setGroupDropdownAdd("");
        setVendorDropdownAdd("");
        setSelectedManufacturers([]);
        setVendorDropdownAdd(null);
        setGroupDropdownAdd(null);
        getUser();
      },
      (error) => {
        showErrorToast(toast, error?.response?.data, 3000, "");
        setUserDialog(false);
        setUserPasswordDailog(false);
        setViewUserDialog(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setVendorDropdownAdd(null);
        setGroupDropdownAdd(null);
        setSelectedManufacturers([]);
        setRole("");
      }
    );
  };

  const getUser = () => {
      getUsers(showInactiveUsers, token,(res) => {
        let users = res?.data.map(rowData => {
          return {
            ...rowData,
            IsActive: UserStatus(rowData),
            CreatedDate: formatDate(rowData.CreatedDate),
            LastLogInDate: formatDate(rowData.LastLogInDate),
            SupplierName: concatSupplierNames(rowData)
          };
        });  
        setData(users);
      },
      (error) => {
        showErrorToast(toast, error?.response?.data?.message, 3000, "");
        console.log(error);
      });
  };

  const viewUser = (userName) => {
    setUserDialog(false);
    setViewUserDialog(true);
    
   viewUsers(userName,token,(res) => {
    setViewData(res?.data);
    setVendorDropdownAddTemp(res?.data?.Supplier?.Suppliers.map((supplier)=> supplier.id));
    setGroupDropdownAdd(res?.data.Supplier.GroupId);
    setSelectedManufacturers(res?.data?.ManufacturersIds);
    setFirstName(res?.data.FirstName);
    setLastName(res?.data.LastName);
    setEmail(res?.data.Email);
    setActive(res?.data.IsActive);
    setSelectedRole(res?.data.RoleName);
    getUser();
  },
   (error) => 
   {
    showErrorToast(toast, error?.response?.data?.message, 3000, "");
    console.log(error);
   })
};

  const editSaveUser = () =>
  {
    let payload = {
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        UserName: email,
        IsActive : active,
        SupplierGroupId: groupDropdownAdd,
        SuppliersIds: vendorDropdownAdd,
        ManufacturersIds: selectedManufacturers,
        Role : selectedRole,
      };
      setSubmitted(true);
      editSaveUsers(payload,token,(res) => 
          {
            const resdata = res;
          if (resdata.status === 200) 
            {
                showSuccessToast(toast, 'Saved Successfully');
                console.log("submitupdateapi",res); 
                getUser();
            }
            else if (resdata.status === 401) 
              {
                showWarningToast(toast, resdata?.statusText);
              } 
            else 
             {
                showErrorToast(toast, resdata?.statusText);
              } 
              setUserDialog(false);
              setViewUserDialog(false);
              setFirstName("");
               setLastName("");
               setEmail("");
              setPassword("");
              setConfirmPassword("");
              setSelectedRole("");
              setGroupDropdownAdd("");
              setVendorDropdownAdd("");
              setVendorDropdownAddTemp("");
              setSelectedManufacturers([]);
              setConfirmPassword("")
              getUser();
        },
      (error) => 
       {
        showErrorToast(toast, error?.response?.data?.message, 3000, "");
             setUserDialog(false);
              setViewUserDialog(false);
              setFirstName("");
               setLastName("");
               setEmail("");
                setPassword("");
               setConfirmPassword("");
              setSelectedRole("");
              setGroupDropdownAdd("");
              setVendorDropdownAdd("");
              setVendorDropdownAddTemp("");
              setSelectedManufacturers([]);
              setConfirmPassword("");
      }); 
  }

  const onChangePassword = () =>
  {
    setNewPassword("");
    let payload = 
    {
      UserId : validEmail,
      Password : newPassword,
    };
    setValidNew(true);
    setValidConfirm(true);
    
      changePassword(payload,token,(res) =>
      {
        console.log(res);
        const resdata = res;
          if (resdata.status === 200) 
            {
              showSuccessToast(toast, 'Password Updated Successfully');
              setConfirmPassword("");
            }
            else if (resdata.status === 401) 
              {
                showWarningToast(toast, resdata?.statusText);
              } 
            else 
             {
                showErrorToast(toast, resdata?.statusText);
              } 
              setConfirmPassword("")
              setUserDialog(false);
              setUserPasswordDailog(false);
              getUser();
      },
      (error) => {
        showErrorToast(toast, error?.response?.data?.message);
      setConfirmPassword("");
        setUserPasswordDailog(false);
        setValidNew(false);
        setValidConfirm(false);
      });
  }

  const groupSuppliers = () =>
{
 
  groupSupplier(false, token,(res) => {
setGroupListDropdown(res?.data)
  },
(error) => {
  showErrorToast(toast, error?.response?.data?.message);
   console.log(error);
});
}

  const getSuppliers =() =>
  {
    if (groupDropdownAdd) {
      getSupplier(groupDropdownAdd,token,(res) => {
          setVendorListDropdown(res?.data[0]?.suppliers);
        },
        (error) => {
          showErrorToast(toast, error?.response?.data?.message);
          console.log(error);
        }
      );  
    }
  }
  const Roles = [
    { label: "Admin", value: "Admin" },
    { label: "Supplier", value: "Supplier" },
    { label: "Viewer",  value: "Viewer"},
    { label: "External Enricher",  value: "External Enricher"},
    { label: "Internal Enricher",  value: "Internal Enricher"},
    { label: "ESG",  value: "ESG"},
    { label: "External Quality Assurance",  value: "External Quality Assurance"},
    { label: "Internal Quality Assurance",  value: "Internal Quality Assurance"},
    { label: "External Auditor",  value: "External Auditor"}   
  ];

  const openNewUser = () => {
    getSuppliers();
    groupSuppliers();
    setSubmitted(false);
    setUserDialog(true);
    setViewUserDialog(false);
    setUserPasswordDailog(false);
    setPasswordVisible(false);
    setConfirmPasswordVisible(false);
  };
 

  const hideDialog = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setNewPassword("");
    setPasswordVisible(false);
    setConfirmPasswordVisible(false);
    setRole("");
    setSelectedRole("");
    setVendorDropdownAdd("");
    setVendorDropdownAddTemp("");
    setGroupDropdownAdd("");
    setVendorListDropdown(null);
    setSelectedManufacturers([]);
    setSubmitted(false);
    setUserDialog(false);
    setViewUserDialog(false);
    setUserPasswordDailog(false);
    
  };
  const modifyUser = (rowData) => {
    groupSuppliers();
    viewUser(rowData.UserName);
    setUserDialog(false);
    setViewUserDialog(true);
    
  };

  const modifyPassword = (rowData) =>
  {
    setValidEmail(rowData.Email);
    setUserDialog(false);
    setUserPasswordDailog(true);
    setPasswordVisible(false);
    setConfirmPasswordVisible(false);
  }

  const handleEmail = (e) => {
    
    if (!emailValidator(e.target.value)) {
      setIsValidEmail(false);
      setEmail(e.target.value);
    } else {
      setEmail(e.target.value);
      setIsValidEmail(true);
    }
    
  };

  useEffect(() => {
    if (vendorDropdownAddTemp) {
      setVendorDropdownAdd(vendorDropdownAddTemp.filter(id => vendorListDropdown.map(s => s.id).includes(id)));
    }
  }, [vendorListDropdown]);

  const handleFirstName = (e) =>
  {
    
    if(!firstNameValidator(e.target.value))
    {
     setValidFirst(false)
     setFirstName(e.target.value)
    }
    else
    {
      setFirstName(e.target.value)
      setValidFirst(true)
    }
  }

  const handleLastName = (e) => 
  {
    if(!lastNameValidator(e.target.value))
    {
     setValidLast(false)
     setLastName(e.target.value)
    }
    else
    {
     setLastName(e.target.value)  
     setValidLast(true)
    }    
  }

  const handleNewPassword =(e) =>
  {
    setNewPassword(e.target.value);
    let newValue = e.target.value;
    if (newValue.length < 1)
    {
      setValidNew(false);
      setValidateNewErrors("Please enter new password.");
    } else if (!passwordValidator(newValue)) 
    {
      setValidNew(false);
      setValidateNewErrors(PASSWORD_REQUIREMENTS);
    } else if (newValue.length >= 1 && password.length >= 1) {
      setValidateNewErrors("");
      setValidNew(true);
      if (newValue === password) {
        setValidNew(false);
        setValidateNewErrors("New password and old password should not be the same.");
      }
    } else {
      setValidateNewErrors("");
      setValidNew(true);
    }
    validateConfirmPassword(
      confirmPassword, 
      e.target.value, 
      "New password and Confirm password should be the same."
    );
  }

  const handleConfirmPassword = (e, flag) =>
  {
    setConfirmPassword(e.target.value);

    let pass;
    let errorMessage = "";
    if (flag === "change") 
    {
      pass = newPassword;
      errorMessage ="New password and Confirm password should be the same.";
    } else if (flag === "add") 
    {
      pass= password;
      errorMessage ="Password and Confirm password should be the same.";
    }

    validateConfirmPassword(e.target.value, pass, errorMessage);
  }

  const validateConfirmPassword = (confirmPass, pass, message) =>
  {
    if (confirmPass !== pass) 
      {
        setValidConfirm(false);
        setValidationConfirmErrors(message);
      } else 
      {
        setValidConfirm(true);
        setValidationConfirmErrors("");
      } 
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
};

const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
};


  const activeHideDailog =() =>
  { 
    setActive(false);
    setActiveDailog(false);
  };

  const inActiveHideDailog = () =>
  {
    setActive(true);
    setInActiveDailog(false);
  };
  const confirmActive = () =>
  {
    setActiveDailog(false);
  }
  const confirmInactive = () =>
  {
    setInActiveDailog(false);
  };
  const onActiveRadio =(e) =>
  {
     setActive(e.value);
     setActiveDailog(true);
     setInActiveDailog(false);
  };
  const onRadioChange = (e) =>
  {
    setActive(e.value);
    setInActiveDailog(true);
    setActiveDailog(false);
  };

   const importCSV =({files}) =>
   {
       const reader = new FileReader();
       reader.onload= (e)=> 
       {
        const data = e.target.value
        const importedData = data.split("\n").map((row) => 
        {
          return{
            id: row.split(",")[0],
            Name : row.split(",")[1],
            RoleName: row.split(",")[2],
          };
        });
        setData(importedData);
       };
       reader.readAsText(files[0]);
   }
 
  const exportCSV =() =>
  {
    dt.current.exportCSV();
  }
  const concatSupplierNames = (item) => {
    return item.Supplier?.Suppliers?.map(supplier => supplier.name).join(', ') || '';
  };
  const concatManufacturers = (item) => {
    return item.Manufacturer?.Manufracturers?.map(Manufacturer => Manufacturer.name).join(', ') || '';
};
//   const exportCSV = () => {
//     const exportData = dt.current.map(item => {
//         const supplierNames = item.Supplier?.Suppliers?.map(supplier => supplier.name).join(', ') || '';
//         return {
//             ...item,
//             GroupName: item.Supplier?.GroupName || '',
//             SupplierName: supplierNames,
//             ManufacturersIds: item.ManufacturersIds,
//             IsActive: item.IsActive ? 'Active' : 'Inactive'
//         };
//     });

//     const headers = dt.current.props.children.map(col => col.props.header).filter(header => header !== 'Actions');
//     const dataFields = dt.current.props.children.map(col => col.props.field).filter(field => field !== 'actions');

//     let csvContent = headers.join(',') + '\n';
//     exportData.forEach(item => {
//         let row = dataFields.map(field => `"${item[field] || ''}"`);
//         csvContent += row.join(',') + '\n';
//     });

//     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//     saveAs(blob, 'export.csv');
// };

  // const isEnabled = isvalidFirst && isvalidLast && isvalidEmail && password && password === confirmPassword &&     
  //   role
  //  && ( (role !== "Supplier" && role !=="SUPPLIER") || 
  //   (
  //     ((role ==="Supplier") || (role ==="SUPPLIER")) && 
  //    groupDropdownAdd && vendorDropdownAdd != null && vendorDropdownAdd !=="")); 
  
  // const validEditUser = firstName.length>1 && lastName.length>0 && 
  //   selectedRole &&
  // ( (selectedRole !== "Supplier" && selectedRole !=="SUPPLIER") || 
  //   (
  //     ((selectedRole ==="Supplier") || (selectedRole ==="SUPPLIER")) && 
  //    groupDropdownAdd && vendorDropdownAdd != null && vendorDropdownAdd !=="")); 
const isEditSupplier = selectedRole === "Supplier" || selectedRole === "SUPPLIER";
const isEditGroupVendorValid = groupDropdownAdd && vendorDropdownAdd && vendorDropdownAdd.length > 0;
const isEditManufacturerValid = Array.isArray(selectedManufacturers) && selectedManufacturers.length > 0;

const validEditUser = firstName.length > 1 && lastName.length > 0 && selectedRole &&
  (
    !isEditSupplier || (isEditGroupVendorValid || isEditManufacturerValid)
  );

  // const isPassword = newPassword.length>7 && newPassword === confirmPassword;

  // const validAddUser = isvalidFirst && isvalidLast && isvalidEmail && validPass && confirmValid && role &&
  // (
  //   (role !== "Supplier" && role !== "SUPPLIER") || 
  //   (
  //     ((role === "Supplier") || (role === "SUPPLIER")) && 
  //     (
  //       (groupDropdownAdd && vendorDropdownAdd != null && vendorDropdownAdd !== "") ||
  //       (!groupDropdownAdd && (vendorDropdownAdd == null || vendorDropdownAdd === "") && selectedManufacturers !== null && selectedManufacturers !== "")
  //     )
  //   )
  // );
  const isSupplier = role === "Supplier" || role === "SUPPLIER";
  const isGroupVendorValid = groupDropdownAdd && vendorDropdownAdd && vendorDropdownAdd.length > 0;
  const isManufacturerValid = Array.isArray(selectedManufacturers) && selectedManufacturers.length > 0;
  
  const validAddUser = isvalidFirst && isvalidLast && isvalidEmail && validPass && confirmValid && role &&
    (
      !isSupplier || (isGroupVendorValid || isManufacturerValid)
    );

  const validChangePass = validNew && confirmValid;

  const handlePassword = (e) => {
    setPassword(e.target.value);
    let newValue = e.target.value;
    if (newValue.length < 1) {
      setValidPass(false);
      setValidationNewErrors("Please enter new password.");
    } else if (!passwordValidator(newValue)) {
      setValidPass(false);
      setValidationNewErrors(PASSWORD_REQUIREMENTS);
    }
    else {
      setValidPass(true);
      setValidationNewErrors("");
    }
    validateConfirmPassword(
      confirmPassword, 
      e.target.value, 
      "Password and Confirm password should be the same."
    );
  };

  const  handleFilterChange = (e) =>
  {
    setRoleFilter(e.target.value);
    setFilters(prev => {
      let filter = JSON.parse(JSON.stringify(prev));
      filter['RoleName'].value = e.target.value;
      return filter;
    });
  }

  const VendorGroupChange=(e)=>{
    setGroupDropdownAdd(e.value);
    setVendorDropdownAdd(null);
    setVendorDropdownAddTemp(null);
    setVendorListDropdown(null);
  }

  const VendorListChange = (e) =>
  {
    setGroupDropdownAdd(e.value);
    setVendorDropdownAdd(null);
    setVendorDropdownAddTemp(null);
    setVendorListDropdown(null);
  }

  const roleChange =(e) =>
  {
      setRole(e.value);
      setGroupDropdownAdd(null);
      setVendorDropdownAdd(null);
      setVendorDropdownAddTemp(null);
  }

  const editRoleChange = (e) =>
  {
     setSelectedRole(e.value);
     setGroupDropdownAdd(null);
     setVendorDropdownAdd(null);
     setVendorDropdownAddTemp(null);
  }

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button label="Add User" style={{backgroundColor:'#003e7e'}}  onClick={openNewUser} />
        <Dropdown
          onChange={handleFilterChange}
          options={Roles}
          value = {roleFilter}
          showClear
          placeholder="Role"
          className="w-full md:w-10rem"
        />
      </div>
    );
  };
  
  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2 align-items-center">
      <React.Fragment>
        <InputSwitch 
          inputId="showInactive" 
          checked={showInactiveUsers} 
          onChange={(e) => setShowInactiveUsers(e.value)} 
        />
        <label htmlFor="showInactive">Show Inactive Users</label>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
          />
        </span>
        <Button 
          label="Import"
          icon="pi pi-upload"
          className="p-button-help"
          style={{backgroundColor:'#003e7e'}}
          onClick={importCSV}
          accept=".csv"
          disabled
        />
         {/* <FileUpload
              mode="basic"
              accept=".csv"
              chooseLabel="Import"
              className="custom-fileupload"
              style={{backgroundColor:'#003e7e'}}
              // className="mr-2 inline-block"
              onUpload={importCSV}
            /> */}
            {/* <div> */}
            <Button
              label="Export"
              icon="pi pi-download"
              className="p-button-help"
              style={{backgroundColor:'#003e7e'}}
              onClick={exportCSV}
              // disabled
            />
            {/* </div> */}
        
      </React.Fragment>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-wrap gap-2">
      <React.Fragment>
        <Button
          label="View/Edit"
          icon="pi pi-pencil"
          rounded
          style={{backgroundColor:'#003e7e'}}
          className="Modify"
          onClick={() => modifyUser(rowData)}
        />
        <Button
          label="Change Password"
          rounded
          style={{backgroundColor:'#003e7e'}}
          className="Modify"
          onClick={() => modifyPassword(rowData)}
        />
      </React.Fragment>
      </div>
    );
  };

  const Concatinate = (data) => {
    return (
      (data.FirstName) + " " + (data.LastName)
    );
  };

  const UserStatus = (data) => {
    return data.IsActive ? "Active" : "InActive";
  };
  
  //Add User
  const userDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" style={{backgroundColor:'#003e7e'}} icon="pi pi-times" onClick={hideDialog} />
      <Button label="Save" style={{backgroundColor:'#003e7e'}} icon="pi pi-check" disabled = {!validAddUser || addUserCount === 1}  onClick={saveUser}  />
    </React.Fragment>
  );
   

  //Edit User
  const userEditDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" style={{backgroundColor:'#003e7e'}} icon="pi pi-times"  onClick={hideDialog} />
      <Button label="Save" style={{backgroundColor:'#003e7e'}} icon="pi pi-check" disabled= {!validEditUser} onClick={editSaveUser} />
    </React.Fragment>
  );

  // modify Roles
  const ModifyPasswordFooter = (
    <React.Fragment>
    <Button label="Cancel" style={{backgroundColor:'#003e7e'}} icon="pi pi-times"  onClick={hideDialog} />
      <Button label="Save" style={{backgroundColor:'#003e7e'}} icon="pi pi-check" disabled = {!validChangePass} onClick={(rowData) =>onChangePassword(rowData)} />
    </React.Fragment>
  );

  const UserInactiveFooter = (
    <React.Fragment>
    <Button label="Cancel" style={{backgroundColor:'#003e7e'}} icon="pi pi-times"  onClick={inActiveHideDailog} />
    <Button label="Confirm" style={{backgroundColor:'#003e7e'}} icon="pi pi-check"  onClick={confirmInactive} /> 
    </React.Fragment>
  )

  const UserActiveFooter = (
    <React.Fragment>
    <Button label="Cancel" style={{backgroundColor:'#003e7e'}} icon="pi pi-times"  onClick={activeHideDailog} />
    <Button label="Confirm" style={{backgroundColor:'#003e7e'}} icon="pi pi-check"  onClick={confirmActive} /> 
    </React.Fragment>
  )
  const formatDate = (dateString) => {
    if (!dateString) return "";
    return moment.utc(dateString).local().format('YYYY-MM-DD HH:mm:ss');
  };

  return (
    <div> 
      <Toast ref={toast} />
      <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
        <>
          <DataTable
            ref={dt}
            value={data}
            dataKey="id"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 15]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
            globalFilter={globalFilter} 
            exportFilename="export"
            filters={filters}
            removableSort
          >
           
            <Column sortField="FirstName" body ={(Concatinate)} header ="Name" sortable exportable={false}></Column>
            <Column field='FirstName' hidden header="First Name"></Column>
            <Column field='LastName' hidden header ="Last Name"></Column>
            <Column field="Email" sortable header="Email"></Column>
            <Column field='Supplier.GroupName' hidden header ="Group Name"></Column>
            <Column field='SupplierName' hidden header ="Supplier Name"></Column>
            <Column field='ManufacturersIds' hidden header ="Manufacturer Name" exportField={concatManufacturers}></Column>
            <Column field="CreatedDate" sortable header="Created Date" ></Column>
            <Column field="LastLogInDate" sortable header="Last Login Date" ></Column>
            <Column field="IsActive" sortable header="User Status"></Column>
            <Column field="RoleName" sortable filter showFilterMenu={false} header="Role"></Column>
            <Column
              header="Actions"
              body={actionBodyTemplate}
              exportable={false} 
              style={{ minWidth: "20rem" }}
            ></Column>
          </DataTable>
        </>
      </div>
      
      {/* Add user functionililty starts here */}
      <Dialog
        visible={userDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="User Details"
        modal
        className="p-fluid"
        footer={userDialogFooter}
        onHide={hideDialog}
      >
        {/* {user.image && (
          <img
            src={`https://primefaces.org/cdn/primereact/images/user/${user.image}`}
            alt={user.image}
            className="user-image block m-auto pb-3"
          />
        )} */}

        <div className="field">
          <label htmlFor="FirstName" className="font-bold">
            First Name <span className="required-field"></span>
          </label>
          <InputText
            id="FirstName"
            value={firstName}
            onChange={(e)=>handleFirstName(e)}
            required 
          />
          {!isvalidFirst && firstName.length !== 0 && <small className='p-error'>Enter valid First Name</small>}
        </div>
        <div className="field">
          <label htmlFor="LastName" className="font-bold">
            Last Name <span className="required-field"></span>
          </label>
          <InputText
            id="LastName"
            value={lastName}
            onChange={(e) =>handleLastName(e)}
            required 
          />
        {!isvalidLast && lastName.length !== 0 && <small className='p-error'>Enter valid Last Name</small>}
        </div>
        <div className="field">
          <label htmlFor="Email" className="font-bold">
            Email <span className="required-field"></span>
          </label>
          <InputText
            id="Email"
            value={email}
            onChange={(e) => handleEmail(e)}
            required 
          />
           {!isvalidEmail && email.length !== 0 && (
                    <small className="p-error">Enter valid email</small>
                  )}
        </div>
        <div className="field">
          <label htmlFor="Password" className="font-bold">
          Password <span className="required-field"></span>
          </label>
          <div className="password-input-container">
        <InputText
            type={passwordVisible ? "password" : "text"}
            id="Password"
            value={password}
            onChange={(e) => handlePassword(e)}
            required 
            className="password-input"
        />
        <Button 
            icon={passwordVisible ? "pi pi-eye-slash" : "pi pi-eye"} 
            onClick={togglePasswordVisibility} 
            className="password-toggle-button"
        />
    </div>
            {!validPass && (<small className="p-error">{validationNewErrors}</small>)}
        </div>

        <div className="field">
          <label htmlFor="ConfirmPassword" className="font-bold">
          Confirm Password <span className="required-field"></span>
          </label>
          <div className="conpassword-input-container">
          <InputText
            type={confirmPasswordVisible ? "password" : "text"}
            required
            id="ConfirmPassword"
            value={confirmPassword}
            onChange={(e) =>handleConfirmPassword(e, "add")} 
          />
          <Button 
            icon={confirmPasswordVisible ? "pi pi-eye-slash" : "pi pi-eye"} 
            onClick={toggleConfirmPasswordVisibility} 
            className="password-toggle-button"
          />
          </div>
          {!confirmValid && (
            <small className="p-error">{validationConfirmErrors}</small>
           )}
        </div>
        <div className="field">
        <label htmlFor="Role" className="font-bold">
              Role <span className="required-field"></span>
        </label>
          <Dropdown
            value={role}
            id="Role"
            onChange={(e)=> roleChange(e)}
            options={Roles}
            placeholder="Select Role"
            className="w-full md:w-10rem"
          />
        </div>
        {(role === "Supplier") || (role === "SUPPLIER") ?
        <div className="field">
          <label htmlFor ="SupplierGroup" className="font-bold">
            Supplier Group {selectedManufacturers == "" && <span className="required-field"></span>}
         </label>
          <Dropdown
            value={groupDropdownAdd}
            optionLabel="name"
            optionValue="id"
            onChange={(e) => VendorGroupChange(e)}
            options={groupListDropdown}
            filter
            placeholder="Suppliers Group List"
            className="w-full"
            showClear
          />
        </div> : ""  
        }
        
        {(role === "Supplier") || (role === "SUPPLIER") ?
        <div className="field">
          <label htmlFor ="SupplierName" className="font-bold">
            Supplier Name {selectedManufacturers == "" && <span className="required-field"></span>}
         </label>
          <MultiSelect
            value={vendorDropdownAdd}
            optionLabel="name"
            optionValue="id"
            onChange={(e) => setVendorDropdownAdd(e.value)}
            options={vendorListDropdown}
            display="chip"
            placeholder="Suppliers List"
            filter
            className="w-full multiSelectWrapChips"
          />
        </div>: ""
         }

        {(role === "Supplier") || (role === "SUPPLIER") ?
        <div className="field">
          <label htmlFor="ManufacturersList" className="font-bold">
          Manufacturer Name {(vendorDropdownAdd == null || vendorDropdownAdd.length === 0) && <span className="required-field"></span>}
          </label>
          <MultiSelect
            id="ManufacturersList"
            value={selectedManufacturers}
            optionLabel="manufacturerName"
            optionValue="manufacturerId"
            onChange={(e) => setSelectedManufacturers(e.value)}
            options={manufacturerList}
            display="chip"
            placeholder="Manufacturers List"
            filter
            className="w-full multiSelectWrapChips"
          />
        </div> : ""
        }
      </Dialog>


      {/* edit user functionililty starts here */}
      {viewData!=null && 
      <Dialog
        visible={viewUserDailog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="User Details"
        modal
        className="p-fluid"
        footer={userEditDialogFooter}
        onHide={hideDialog}
      >
        {/* {user.image && (
          <img
            src={`https://primefaces.org/cdn/primereact/images/user/${user.image}`}
            alt={user.image}
            className="user-image block m-auto pb-3"
          />
        )} */}
        
        <div className="field">
          <label htmlFor="FirstName" className="font-bold">
            First Name <span className="required-field"></span>
          </label>
          <InputText
            id="FirstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required 
            className={classNames({
              "p-invalid": submitted && !firstName,
            })}
          />
          {submitted && !firstName &&  (
            <small className="p-error">First Name is required.</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="LastName" className="font-bold">
            Last Name <span className="required-field"></span>
          </label>
          <InputText
            id="Lastame"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            className={classNames({ "p-invalid": submitted && !lastName })}
          />
          {submitted && !lastName && (
            <small className="p-error">Last Name is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="Email" className="font-bold">
            Email <span className="required-field"></span>
          </label>
          <InputText
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
        </div>
          <div className="field">
            <label htmlFor="UserName" className="font-bold" >
              UserName <span className="required-field"></span>
            </label>
            <InputText
              id="UserName"
              value={viewData.UserName}
              disabled
              />
          </div>
          <div className="field">
          <label htmlFor="RoleName" className="font-bold">
              Role <span className="required-field"></span>
           </label>
          <Dropdown
            value={selectedRole}
            id="Role"
            options={Roles}
            className="w-full md:w-10rem"
            onChange={(e) => editRoleChange(e)}
          />
        </div>
        {(selectedRole === "Supplier") || (selectedRole === "SUPPLIER")  ?
        <div className="field">
          <label htmlFor ="SupplierGroup" className="font-bold">
            Supplier Group {selectedManufacturers == "" && <span className="required-field"></span>}
         </label>
          <Dropdown
            value={groupDropdownAdd}
            optionLabel="name"
            optionValue="id"
            onChange={(e)=>VendorListChange(e)}
            options={groupListDropdown}
            placeholder="Suppliers Group List"
            className="w-full"
            showClear
            filter
          />
        </div>: ""
        }

        {(selectedRole === "Supplier") || (selectedRole === "SUPPLIER")  ?
        <div className="field">
          <label htmlFor ="SupplierName" className="font-bold">
            Supplier Name{selectedManufacturers == "" && <span className="required-field"></span>}
         </label>
          <MultiSelect
            value={vendorDropdownAdd}
            optionLabel="name"
            optionValue="id"
            onChange={(e)=>setVendorDropdownAdd(e.value)}
            options={vendorListDropdown}
            display="chip"
            filter
            placeholder="Suppliers List"
            className="w-full multiSelectWrapChips"
          />
        </div>: ""
        }

        {(selectedRole === "Supplier") || (selectedRole === "SUPPLIER")  ?
        <div className="field">
          <label htmlFor="ManufacturersList" className="font-bold">
          Manufacturer Name {(vendorDropdownAdd == null || vendorDropdownAdd.length === 0) && <span className="required-field"></span>}
          </label>
          <MultiSelect
            id="ManufacturersList"
            value={selectedManufacturers}
            optionLabel="manufacturerName"
            optionValue="manufacturerId"
            onChange={(e) => setSelectedManufacturers(e.value)}
            options={manufacturerList}
            display="chip"
            placeholder="Manufacturers List"
            filter
            className="w-full multiSelectWrapChips"
          />
        </div> : ""
        }


      <div className="flex flex-wrap gap-3">
        <div className="flex align-items-center">
          <RadioButton name="active" value={true} onClick={onActiveRadio}
          checked={active===true}/>
          <Dialog 
           visible={activeDailog}
           style={{ width: "25rem" }}
           breakpoints={{ "960px": "75vw", "641px": "90vw" }}
           header="Active User"
           modal
           className="p-fluid"
           footer={UserActiveFooter}
           onHide={activeHideDailog}
          >
            <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              Are you sure you want to ReActivate the User?
            </span>
          }
        </div>
          </Dialog>
          
          <label htmlFor="active1" className="p-radiobutton-label">Active</label> 
       </div>
        <div className="flex align-items-center">
          <RadioButton name="inActive" value={false} onClick={onRadioChange}
          checked={active===false}/>
          <Dialog 
           visible={inActiveDailog}
           style={{ width: "25rem" }}
           breakpoints={{ "960px": "75vw", "641px": "90vw" }}
           header="InActive User"
           modal
           className="p-fluid"
           footer={UserInactiveFooter}
           onHide={inActiveHideDailog}
          >
            <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              Are you sure you want to Inactivate the User?
            </span>
          }
        </div>
          </Dialog>
          <label htmlFor="active2" className="p-radiobutton-label">In-Active</label>
        </div>
      </div>
      </Dialog>
      }

       {/* change password Functionality */}
     <Dialog 
      visible={userPasswordDailog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Update Password"
        modal
        className="p-fluid"
        footer={ModifyPasswordFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="New Password" className="font-bold">
            New Password <span className="required-field"></span>
          </label>
          <InputText
            type={passwordVisible ? "text" : "password"}
            id="NewPassword"
            value={newPassword}
            onChange={(e) => handleNewPassword(e)}
            required 
            className="w-full md:w-25rem"
          />
          <Button
              icon={passwordVisible ? "pi pi-eye" : "pi pi-eye-slash"}
              onClick={togglePasswordVisibility}
          />

          <div>
            {!validNew && (<small className="p-error">{validateNewErrors}</small>)}
          </div>
        </div>
        <div className="field">
          <label htmlFor="Confirm Password" className="font-bold">
            Confirm Password <span className="required-field"></span>
          </label>
          <InputText
            type={confirmPasswordVisible ? "text" : "password"}
            id="ConfirmPassword"
            value={confirmPassword}
            onChange={(e) => handleConfirmPassword(e, "change")}
            required 
            className="w-full md:w-25rem"
          />
          <Button
              icon={confirmPasswordVisible ? "pi pi-eye" : "pi pi-eye-slash"}
              onClick={toggleConfirmPasswordVisibility}
          />
          <div>
           {!confirmValid && (<small className="p-error">{validationConfirmErrors}</small>)}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
